import { environment } from "../utils/environment";
import { Sku } from "../types/Sku";
import { NftMetaData } from "types/Nft";

const baseFetch = <T>(
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body?: any
) => {
  return new Promise<T>((resolve, reject) => {
    fetch(environment.mongo_api_base_url + endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body:
        method === "GET" || method === "DELETE" ? null : JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVipStakingLevels = () => {
  return baseFetch<any>("/configs/vipbenefits");
};

export const getSkuList = () => {
  return baseFetch<Sku[]>("/skulist");
};

export const getServerTime = async () => {
  const serverTimestamp = await baseFetch<number>("/configs/time");
  return serverTimestamp || null;
};

export const getResourceBundleNftMetadata = async (tokenId: string) => {
  const nftMetadata = await baseFetch<NftMetaData>(
    `/nfts/resource_bundle/metadata?token_id=${tokenId}`
  );
  return nftMetadata;
};

export const getGenesisNftMetadata = async (tokenId: string) => {
  const nftMetadata = await baseFetch<NftMetaData>(
    `/nfts/herogear/metadata?tokenId=${tokenId}`
  );
  return nftMetadata;
};

export const getNftMetadataFromServer = async (tokenId: string) => {
  const landMetadata = await baseFetch<any>(
    `/nfts/metadata?TokenId=${tokenId}`
  );
  return landMetadata;
};

export const subsribeForNewsletter = async (email: string) => {
  const subscriptionResponse = await baseFetch<any>(
    `/newsletter/subscribe`,
    "POST",
    {email}
  );
  return subscriptionResponse;
}
