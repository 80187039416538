export const toggleBoolean = (prev: boolean) => !prev;

const isValidArrayIndex = (arr: any[], idx: number) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr: any[], idx: number, value: any) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr: any[], idx: number, newValue: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr: any[], idx: number, updater: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr: any[], idx: number) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function decimalToPercentage(decimal: any) {
  if (decimal === 0) {
    return 0;
  } else {
    return (decimal * 100);
  }
}

export function addThousandsSeparator(input: string | number | undefined) {
  if(!input) return "";
  // Convert the input to a string
  let numberStr = String(input);

  // Add commas as thousands separators
  numberStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberStr;
}

export function hideWalletAddress(walletAddress: string) {
  if(!walletAddress) return "";
  
  return (
    walletAddress.substring(0, 5) +
    "..." +
    walletAddress.substring(walletAddress?.length - 4, walletAddress?.length)
  );
}

export function truncateUrl(url: string) {
  const maxLength = 30; // maximum number of characters to display
  return url.length > maxLength ? url.substring(0, maxLength) + '...' : url;
};

export function formatNumberInLiteral(num: string | number | undefined) {
  if(!num) return "";
  if(typeof num === "string") num = Number(num);
  // If the number is less than 100, return it as is
  if (num < 100) return num.toString();

  let units = ["", "K", "M", "B", "T"];
  let unitIndex = Math.floor((num.toString().length - 1) / 3) * 3;
  let unitValue = Math.pow(10, unitIndex);
  let numStr = (num / unitValue).toFixed(1);
  let unit = units[Math.floor(unitIndex / 3)];
  
  // Remove unnecessary decimals
  if (numStr.indexOf('.0') === numStr.length - 2) {
    numStr = numStr.substring(0, numStr.length - 2);
  }

  return numStr + unit;
}

export function getFormattedTimeDifference(currentTimestamp: number, endTimestamp: number) {
  // Calculate the difference in milliseconds
  const difference = endTimestamp - currentTimestamp;

  // Calculate days, hours, minutes
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);

  let result = "";
  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += minutes + "m";

  return result.trim();
}

export function getUtcEndTimestamps() {
  const now = new Date();

  // End of today in UTC
  const endOfDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  // End of current week in UTC (assuming week ends on Saturday)
  const endOfWeek = new Date(Date.UTC(
    now.getUTCFullYear(), 
    now.getUTCMonth(), 
    now.getUTCDate() + (6 - now.getUTCDay()), // Move to the next Saturday
    23, 59, 59, 999
  ));

  // End of current month in UTC
  const endOfMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59, 999));

  return {
    endOfDayTimestamp: endOfDay.getTime(),
    endOfWeekTimestamp: endOfWeek.getTime(),
    endOfMonthTimestamp: endOfMonth.getTime()
  };
}

export function removeDuplicates(arr?: any[], caseInsensitive: boolean = false) {
  if (!arr) return [];

  if (caseInsensitive) {
    const lowerCaseSet = new Set();
    const unique = [];
    for (const item of arr) {
      const value = typeof item === 'string' ? item.toLowerCase() : item;
      if (!lowerCaseSet.has(value)) {
        lowerCaseSet.add(value);
        unique.push(item);
      }
    }
    return unique;
  } else {
    const unique = arr.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return unique;
  }
}

export function reload() {
  if(typeof window !== 'undefined') {
    window.location.reload();
  }
}

export function getWindow() {
  if(typeof window !== 'undefined') {
    return window;
  }
}

export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}